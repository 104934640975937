@import "src/style";

.container {
  position: absolute;
  background-color: #161619;
  border: solid 2px #000000;
  border-radius: 10px;
  overflow: hidden;
  top: 12%;
  
  @include media('<=phone') {
    width: 100%;
  }

  @include media('>phone') {
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}
