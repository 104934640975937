@import "src/style";

.app {
  flex: 1;
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100%;
  min-width: 320px;

  @include media('>phone') {
    min-height: 800px;
    min-width: 780px;
  }
}

.header {
  display: flex;
  justify-content: center;
  min-width: 120px;
  padding-top: 8%;
  width: 10%;
  
  @include media('>phone') {
    align-items: flex-end;
    padding-bottom: 5%;
  }

  h1 {
    color: #ffffff;
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    padding: 0;
    margin: 0;
  }
}

.footer {
  align-items: flex-start;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  
  @include media('<=phone') {
    padding-right: 8%;
    padding-top: 8%;
    position: relative;
    top: -4px;
  }

  @include media('>phone') {
    padding-right: 2%;
    padding-bottom: 5%;
    align-items: flex-end;
  }
}

.menu {
  display: none;
  
  @include media('>phone') {
    flex-direction: row;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0 0 0 32px;
  }
}

.menuMobile {
  background: #050505;
  flex-direction: column;
  display: flex;
  list-style: none;
  margin: 0;
  height: 100%;
  padding: 16px 48px;

  .menuItem {
    margin-top: 32px;
    margin-right: 0;
  }
}

.menuMobileWrapper {
  @include media('>phone') {
    display: none;
  }
}

.menuItem {
  margin-right: 32px;
  
  a {
    color: #ffffff;
    font-size: 16px;
    font-weight: normal;
    text-decoration: none;

    &:hover {
      color: #e0e0e0;
    }
  }
}
